import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/Home";
import Raffle from "./Pages/Raffle";
import RafflePage from './Pages/RafflePage';

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='/RafflePage' element={<RafflePage />} />
					<Route path='/Raffle' element={<Raffle />} />
 
				</Routes>
			</BrowserRouter>
			<div>
		

			</div>
		</div>




	)
}


export default App;
