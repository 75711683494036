/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';
import twitter from '../assets/twitter-.png';
import facebook from '../assets/facebook.png';
import instagram from '../assets/instagram.png';
import whatsapp from '../assets/whatsapp.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import logoPic from '../assets/logo.jpg';
import ticket from '../assets/movie.png';
import money from '../assets/money.png';
import Footer from '../components/footer';
import line3 from '../assets/line3.jpg';
import money2 from '../assets/1710350929.jpeg';
import user from '../assets/profile-user.png';
import calander from '../assets/calendar.png';
import location from '../assets/placeholder.png';
import ticket2 from '../assets/ticket.png';
import coupon from '../assets/coupon.png';
import back from '../assets/previous.png'

import $ from 'jquery';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
//import { mainnet } from 'wagmi/chains';


var Scroll = require('react-scroll');

$(window).on('load', function () {
    setTimeout(function () { // allowing 3 secs to fade out loader
        $('.page-loader').fadeOut('slow');
    }, 2000);
});

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    /*	if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {*/
    // Render a countdowns

    if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

        window.location.reload(true);
        console.log("Mint Begins");
    }


    return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
    /*	}*/
};

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
    window.open("https://twitter.com/Lolasdinodream");
}

const discord = () => {
    window.open("#");
}

const os = () => {
    window.open("https://opensea.io/collection/lola-s-dinodreams-a-magical-journey-with-lemon");
}

let ABI = [
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "receiver",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "quantity",
                "type": "uint256[]"
            }
        ],
        "name": "airdrop",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_initBaseURI",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_initNotRevealedUri",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_contractURI",
                "type": "string"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalToCurrentOwner",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "ApproveToCaller",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "MintToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "OperatorNotAllowed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerIndexOutOfBounds",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TokenIndexOutOfBounds",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToNonERC721ReceiverImplementer",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "URIQueryForNonexistentToken",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_newBaseURI",
                "type": "string"
            }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_contractURI",
                "type": "string"
            }
        ],
        "name": "setContractURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_max_per_wallet",
                "type": "uint256"
            }
        ],
        "name": "setMax_per_wallet",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_MAX_SUPPLY",
                "type": "uint256"
            }
        ],
        "name": "setMAX_SUPPLY",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_notRevealedURI",
                "type": "string"
            }
        ],
        "name": "setNotRevealedURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_publicSaleCost",
                "type": "uint256"
            }
        ],
        "name": "setPublicSaleCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_receiver",
                "type": "address"
            },
            {
                "internalType": "uint96",
                "name": "_royaltyFeesInBips",
                "type": "uint96"
            }
        ],
        "name": "setRoyaltyInfo",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_total_wl_limit",
                "type": "uint256"
            }
        ],
        "name": "setTotal_wl_limit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_whitelistCost",
                "type": "uint256"
            }
        ],
        "name": "setWhitelistCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_wl_per_wallet",
                "type": "uint256"
            }
        ],
        "name": "setWl_per_wallet",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggle_public_mint_status",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggle_wl_mint_status",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggleReveal",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "wl",
                "type": "address[]"
            }
        ],
        "name": "whitelistAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "whitelistMint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "contractURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getBaseURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "max_per_wallet",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "MAX_SUPPLY",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "notRevealedUri",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "OPERATOR_FILTER_REGISTRY",
        "outputs": [
            {
                "internalType": "contract IOperatorFilterRegistry",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "public_mint_status",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "publicMinted",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "publicSaleCost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "revealed",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_salePrice",
                "type": "uint256"
            }
        ],
        "name": "royaltyInfo",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenOfOwnerByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "total_wl_count",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "total_wl_limit",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "whitelistCost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "wl_mint_status",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "wl_per_wallet",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "wlEligible",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "wlMinted",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

let address = "0xe0c491A65499d94B8E225c88258f56C4f093dd92";

const RafflePage = () => {

    const { open } = useWeb3Modal()
    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()

    const { address: walletAddress } = useAccount({
        async onConnect() {
            handleConnect()
        }
    })

    const publicClient = createPublicClient({
        chain: mainnet,
        transport: http()
    })

    const [_totalSupply, settotalSupply] = useState(0)
    const [statusError, setstatusError] = useState("")
    const [statusLoading, setstatusLoading] = useState("")
    const [success, setsuccess] = useState("")
    const [nftMintingAmount, setnftMintingAmount] = useState(1)
    const [_publicMintMsg, set_publicMintMsg] = useState("Mint Here")
    const [_cost, set_publicSaleCost] = useState("")
    const [_wlcost, set_wlcost] = useState("")
    const [_max_per_wallet, set_max_per_wallet] = useState(10)
    const [_owner, set_owner] = useState("")
    const [myNFTWallet, setmyNFTWallet] = useState(0)
    const [_whitelist_mint_status, set_whitelist_mint_status] = useState("")
    const [_public_mint_status, set_public_mint_status] = useState("")
    const [_whitelistLimitPerWallet, set_whitelistLimitPerWallet] = useState("")
    const [_whitelistCount, set_whitelistCount] = useState("")
    const [_whitelistLimit, set_whitelistLimit] = useState("")
    const [_MAX_SUPPLY, set_MAX_SUPPLY] = useState("")
    const [_navbarOpen, set_navbarOpen] = useState(0)
    const [_connected, setConnected] = useState(false)
    const [_freeMinLimit, set_OverallFreeMintLimit] = useState(600);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }
    //........................................//
    const contract = {
        address: address,
        abi: ABI
    }

    async function handleConnect() {
        if (chain.id !== 1) {
            switchNetwork(1)
        }

        var data = await getBalance();
        setmyNFTWallet(Number(data.data));
        console.log("myNFTWallet :" + data.data);
        setConnected(true);
    }

    const { refetch: getTotalSupply } = useContractRead({
        ...contract,
        functionName: 'totalSupply',
    })

    const { refetch: getCost } = useContractRead({
        ...contract,
        functionName: 'publicSaleCost',
    })

    const { refetch: getBalance } = useContractRead({
        ...contract,
        functionName: 'balanceOf',
        args: [walletAddress ? walletAddress : '0x']
    })


    useEffect(() => {

        async function fetchData() {
            var data = await getTotalSupply();

            settotalSupply(Number(data.data))
            console.log("totalSupplyFromUseffect : " + data.data)

        }
        async function fetchData2() {

            var data1 = await getBalance();
            setmyNFTWallet(Number(data1.data));
            console.log("myNFTWallet :" + data1.data);
        }

        async function fetchData3() {

            var data2 = await getCost();
            set_publicSaleCost(Number(data2.data) / 10 ** 18);
            console.log("publicSaleCost :" + data2.data);
        }

        if (_connected) {
            fetchData();
            fetchData2();
            fetchData3();
        }

        // eslint-disable-next-line no-use-before-define
    }, [_connected, getBalance, getCost, getTotalSupply]);

    const { data, refetch, isSuccess } = useContractReads({
        contracts: [
            { ...contract, functionName: 'totalSupply' },
            { ...contract, functionName: 'max_per_wallet' },
            { ...contract, functionName: 'wlCost' },
            { ...contract, functionName: 'owner' },
            { ...contract, functionName: 'publicSaleCost' },
            { ...contract, functionName: 'whitelistCount' },
            { ...contract, functionName: 'whitelistLimit' },
            { ...contract, functionName: 'MAX_SUPPLY' },
            { ...contract, functionName: 'whitelistLimitPerWallet' },
            { ...contract, functionName: 'whitelist_mint_status' },
            { ...contract, functionName: 'public_mint_status' },
            { ...contract, functionName: 'overallFreeMintLimit' }

        ]
    },)

    useMemo(() => {

        if (isSuccess === true) {
            settotalSupply(Number(data[0].result))
            set_max_per_wallet(Number(data[1].result))
            //set_wlcost(formatEther(data[2].result))
            set_wlcost(Number(data[2].result) / 10 ** 18); // Convert WEI to ETH
            set_owner(data[3].result)
            //set_publicSaleCost(formatEther(data[4].result))
            set_publicSaleCost(Number(data[4].result) / 10 ** 18); // Convert WEI to ETH
            set_whitelistCount(data[5].result)
            set_whitelistLimit(data[6].result)
            set_MAX_SUPPLY(data[7].result)
            set_whitelistLimitPerWallet(data[8].result)
            set_whitelist_mint_status(data[9].result)
            set_public_mint_status(data[10].result)
            set_OverallFreeMintLimit(data[11].result)

            console.log("_totalSupply: " + _totalSupply)
        }
    }, [_totalSupply, data, isSuccess])



    const { writeAsync } = useContractWrite({
        ...contract,
        functionName: 'mint',
        onError(error) {
            if (error.message.includes('balance')) {
                setstatusError(true)
                setstatusLoading(false)
            }
        }
    })
    //........................................//

    async function onPlus() {

        console.log("_whitelist_mint_status :" + _whitelist_mint_status);
        console.log("_public_mint_status :" + _public_mint_status);
        var nftIhave = nftMintingAmount + myNFTWallet;


        console.log("myNFTWallet : " + myNFTWallet);
        console.log("nftMintingAmount : " + nftMintingAmount);


        console.log("nftIhave : " + nftIhave);


        if (_whitelist_mint_status === true) {

            if (nftIhave < _whitelistLimitPerWallet) {

                setnftMintingAmount(nftMintingAmount + 1);

            }
        } else {
            console.log("1");
            if (_public_mint_status === true) {
                console.log("2");

                if (nftIhave < _max_per_wallet) {
                    //if (nftIhave < 1) {
                    console.log("3");

                    setnftMintingAmount(nftMintingAmount + 1);
                    console.log("4");


                    //}
                }
            }

        }
    }

    async function onMinus() {
        if (nftMintingAmount != 1) {
            setnftMintingAmount(nftMintingAmount - 1)
        }
    }


    async function onMint() {
        try {
            var mintCost;
            var fullTotalSupply = 0;
            fullTotalSupply = (nftMintingAmount) + (_totalSupply);
            console.log("fullTotalSupply : " + fullTotalSupply);


            if (_owner === walletAddress) {

                mintCost = '0'

            } else {

                if (fullTotalSupply <= 0) {
                    mintCost = '0'
                } else

                    if (_whitelist_mint_status === true) {
                        //mintCost = parseFloat(_cost)
                        //mintCost = parseFloat(_wlcost)
                        mintCost = _wlcost;

                    } else {
                        if (_public_mint_status === true) {
                            //mintCost =  parseFloat(_cost)
                            mintCost = _cost;
                            console.log("mintCostB : " + mintCost);
                        }
                    }
            }

            mintCost = (mintCost * nftMintingAmount).toString();
            console.log("nftMintingAmountA : " + nftMintingAmount)

            console.log("mintCostA : " + mintCost);


            setstatusLoading(true)
            setstatusError(false)

            var res = await writeAsync({
                args: [nftMintingAmount],
                value: parseEther(mintCost)// mintCost
            })
            var result = await publicClient.waitForTransactionReceipt(res)
            if (result.status === 'success') {
                setstatusError(false)
                setsuccess(true)
                setstatusLoading(false)
                await new Promise(resolve => setTimeout(resolve, 5000));
                window.location.reload(true);
            }
            else {
                setsuccess(false)
                setstatusError(true)
                setstatusLoading(false)

            }
        }
        catch (e) {
            console.log(e)
            setstatusError(true)
            setstatusLoading(false)

        }
    }



    return (
        <div class="allWrap">
            <div class="light">
                <div className='back' onClick={() => window.location.href = 'Raffle'}><img src={back}/>Back to list</div>
                <div className="popup-content2">
                    <div className='picAndDetails'>
                        <img className='detailslPic' src={money2} />
                        <div className='mainR2'>
                            <div className='section1'>
                                <div className='s1H'>One Million MXN Pesos</div>
                                <div className='s1Sub'>Price per ticket</div>
                                <div className='amountR'>$50.00</div>
                                <div className='description'>Win a million pesos for only fifty pesos a ticket</div>
                            </div>

                            <div className='lineDash'></div>

                            <div className='section2'>
                                <div className='s2H'><img src={coupon} />Details</div>
                                <div className='flexDetails'>
                                    <div className='leftS'>State</div>
                                    <div className='rightS'>New</div>
                                </div>

                                <div className='flexDetails'>
                                    <div className='leftS'>Average value</div>
                                    <div className='rightS'>$1,000,000.00</div>
                                </div>

                            </div>

                            <div>
                                <div className='bar'></div>
                                <div className='note'>Note: A minimum of 80% of tickets sold is required to hold this raffle.</div>
                            </div>
                        </div>
                    </div>

                    <div className='userMain'>
                        <div className='userDetails'>
                            <div className='s2H'><img src={coupon} />Rifle Information</div>
                            <div className='user'><img src={user} />Eric Nam</div>

                            <div>
                                <div className='endDate'>End Date <img src={calander} /></div>

                                <div class="timer">
                                    <div>
                                        <div id="days">35{/*Number(_days)*/}</div>
                                        <span>days</span>
                                    </div>
                                    <div>
                                        <div id="hours">12{/*Number(_hours)*/}</div>
                                        <span>hours</span>
                                    </div>
                                    <div>
                                        <div id="minutes">2{/*Number(_minutes)*/}</div>
                                        <span>minutes</span>
                                    </div>
                                    <div>
                                        <div id="seconds">25{/*Number(_seconds)*/}</div>
                                        <span>seconds</span>
                                    </div>
                                </div>
                            </div>

                            <div className='location'>
                                <div className='endDate'>Prize Location <img src={location} /></div>
                                <div className='city'>Mexico City, CDMX</div>
                            </div>
                        </div>

                        <div className='userTicket'>
                            <div className='s2H'><img src={coupon} />Top of tickets purchased</div>

                            <div className='ticketDetailsMain'>
                                <div className='ticketDetails'>
                                    <img src={ticket2} />
                                    <div>$60</div>
                                </div>

                                <div className='ticketDetails'>
                                    <img src={ticket2} />
                                    <div>$120</div>
                                </div>

                                <div className='ticketDetails'>
                                    <img src={ticket2} />
                                    <div>$50</div>
                                </div>

                            </div>

                            <div className='ticketDetailsMain'>
                                <div className='ticketDetails'>
                                    <img src={ticket2} />
                                    <div>$160</div>
                                </div>

                                <div className='ticketDetails'>
                                    <img src={ticket2} />
                                    <div>$40</div>
                                </div>

                                <div className='ticketDetails'>
                                    <img src={ticket2} />
                                    <div>$70</div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className='note2Main'>
                        <div className='s2H'><img src={coupon} />Report Raffle</div>

                        <div className='note2'>
                            <div>
                                <div className='noteC'>Note: You can report this raffle if it has inappropriate content and/or sensitive information for some users.</div>
                            </div>

                            <div className='report'><button>Report the Ruffle</button></div>
                        </div>
                    </div>
                </div>

            </div >
			<img className='line2' src={line3} />

            <Footer />

        </div >
    )

}
export default RafflePage;
