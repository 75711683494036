import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import banner2 from '../assets/box.png';
import img2 from '../assets/together.png';
import img3 from '../assets/park-tickets-couple.png';
import img4 from '../assets/megaphone.png';
import img5 from '../assets/save-money.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Token extends Component {

    render() {
        return (

            <div className='about'>
                <div className='about-2'>
                    <div className='titleA'>¿CÓMO FUNCIONA?</div>

                    <div className='aboutSection1'>
                        <img className='img1B' src={banner2} />
                        <div>App/Sitio web con suite de herramientas que facilitan la creación de un evento personal al organizador.</div>
                    </div>

                    <div className='aboutSection2'>

                        <div className='aboutSection2Sub'>
                            <img className='img1' src={img2} />
                            <div>Organizador administra y opera su evento, permitiendo la compra de boletos emitidos.</div>
                        </div>

                        <div className='aboutSection2Sub-2'>
                            <img className='img1' src={img3} />
                            <div>Participante compra boleto(s).</div>
                        </div>

                        <div className='aboutSection2Sub-2'>
                            <img className='img1' src={img4} />
                            <div>Se lleva a cabo digitalmente y con autonomía del evento de rifa.</div>
                        </div>

                        <div className='aboutSection2Sub'>
                            <img className='img1' src={img5} />
                            <div>Organizador envía el producto al participante ganador, se valida la entrega y recibe su dinero.</div>
                        </div>
                    </div>

                    <div className='aboutSection2Mobile'>

                        <div className='aboutSection2Mobile-sub'>
                            <div className='aboutSection2Sub'>
                                <img className='img1' src={img2} />
                                <div>Organizador administra y opera su evento, permitiendo la compra de boletos emitidos.</div>
                            </div>

                            <div className='aboutSection2Sub-2'>
                                <img className='img1' src={img3} />
                                <div>Participante compra boleto(s).</div>
                            </div>
                        </div>

                        <div className='aboutSection2Mobile-sub'>
                            <div className='aboutSection2Sub-2'>
                                <img className='img1' src={img4} />
                                <div>Se lleva a cabo digitalmente y con autonomía del evento de rifa.</div>
                            </div>

                            <div className='aboutSection2Sub'>
                                <img className='img1' src={img5} />
                                <div>Organizador envía el producto al participante ganador, se valida la entrega y recibe su dinero.</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}

export default Token;

