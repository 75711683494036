import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import img1 from '../assets/mny.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Steps extends Component {

    render() {
        return (

            <div className='steps'>

                <img src={img1} />

                <div className='contentsMain'>
                    <div className='titleS'>¡RIFA Y GANA!</div>
                    <div className='titleS2'>Podrás ganar artículos como:</div>


                    <div className='contents'>
                        <div className='contents1'>
                            <p>> Dispositivos móviles</p>
                            <p>> Relojes inteligentes</p>
                            <p>> Aparatos domésticos</p>
                        </div>

                        <div>
                            <p>> Automóviles</p>
                            <p>> Ropa, bolsas y accesorios</p>
                            <p>> Y más</p>
                        </div>
                    </div>

                    <button className='stepsBtn'>¡Rifa ahora!</button>
                </div>


            </div>

        )
    }
}

export default Steps;

