import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Footer extends Component {

    render() {
        return (

            <footer class="simple-footer">
                <div class="container">
                    <div class="footer-content">
                        <div class="footer-section">
                            <h2>About Us</h2>
                            <p>Short description about the company.</p>
                        </div>
                        <div class="footer-section">
                            <h2>Quick Links</h2>
                            <ul>
                                <li><a href="#home">Home</a></li>
                                <li><a href="#services">Services</a></li>
                                <li><a href="#contact">Contact</a></li>
                            </ul>
                        </div>
                        <div class="footer-section">
                            <h2>Contact Us</h2>
                            <p>Email: info@example.com</p>
                            <p>Phone: +123 456 7890</p>
                        </div>
                    </div>
                    <div class="footer-bottom">
                        <p>© 2024 Servicios Comerciales Rafiki SA de CV - Todos los derechos reservados</p>
                    </div>
                </div>
            </footer>

        )
    }
}

export default Footer;

