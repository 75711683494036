import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import mobile from '../assets/collage-telefonos.webp';
import img1 from '../assets/organizador.png';
import img2 from '../assets/participante.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Intro extends Component {

    render() {
        return (

            <div className='introC'>

                <div className='mobileSection'>
                    <div className='mobCon1'>ORGANIZA TU RIFA Y EMPIEZA A GANAR</div>
                    <img src={mobile} />
                    <div className='mobCon2'>JUEGA Y GANA ARTÍCULOS DESDE TU CELULAR</div>
                </div>

                <div className='imgAndConMain'>
                    <div className='imgAndCon'>
                        <img src={img1} />
                        <div>Organizador</div>
                        <p>Organiza y promueve tus rifas usando las herramientas disponibles en la app.</p>
                    </div>

                    <div className='imgAndCon'>
                        <img src={img2} />
                        <div>Participante</div>
                        <p>Como participante encuentra lo que te guste, compra boletos y monitorea el evento.</p>
                    </div>
                </div>

            </div>

        )
    }
}

export default Intro;

